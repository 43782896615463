section{
    padding:2rem 9%;
  }

.footer .box-container {
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
    font-size: 20px;
    justify-content: space-between;
  }

  .footer .box-container .box{
    padding:1rem;
    flex:auto;
    height: 380px;
    width: 250px;
    font-size: 10px;
  }
  
  .footer .box-container .box h3{
    font-size: 2.5rem;
    padding:.7rem 0;
    color:#fff;
  }
  
  .footer .box-container .box p{
    padding:.7rem 0;
    color:#eee;
  }
  
  .footer .box-container .box a{
    display: block;
    padding:.7rem 0;
    color:#eee;
  }
  
  .footer .box-container .box a:hover{
    color:var(--orange);
    text-decoration: underline;
  }
  
  h3,a{
    text-decoration: none;
    color: white;
    font-size: 15px;
  }

li{
    list-style:none;
    color: white;
}

.box>img{
    padding: 0.5rem;
}

.box>img:hover{
    cursor: pointer;
}

.credits{
    text-align: center;
   
}

@media screen and ( max-width: 768px){
    .footer .box-container{
        flex-direction: column;
        gap: 1rem;
    }
    .footer .box-container .box:nth-child(1){
      padding: 0px;
        height: 200px;
    }
    .footer .box-container .box:nth-child(2){
        height: 200px;
        margin-top: 5rem;

    }
    .footer .box-container .box:nth-child(3){
        height: 200px;
        margin-top: 5rem;
    }
    .credits{
        margin-top: 7rem;
        padding-top: 7rem;
        color: white;
        font-size: 15px;
    }
}



  